<template>
  <div class="card card-custom card-stretch">
    <div class="card-header">
      <div class="card-title">{{ $t('label.products') }}</div>
    </div>
    <div class="card-body">
      <div v-if="products" class="d-flex flex-column justify-content-between h-100">
        <div>
          <div v-for="(product, idx) in products"
               :key="idx"
               class="product-item d-flex align-items-center bg-light rounded flex-grow-1 px-3 py-5"
               :class="[idx > 0 ? 'mt-3' : '']"
          >
            <!--begin::Section-->
            <div class="d-flex justify-content-between align-items-center w-100 px-3">
              <div class="symbol symbol-60 symbol-circle">
                <span class="d-flex flex-column symbol-label bg-white">
                  <span class="font-size-h4">{{ product.price }}</span>
                  <span class="font-size-xs">{{ $t('label.point_s') }}</span>
                </span>
              </div>
              <!--begin::Info-->
              <div class="d-flex flex-column w-50">
                <span class="text-gold font-weight-bold font-size-h3">{{ product.name }}</span>
                <span class="font-size-sm font-weight-bold">{{ product.description }}</span>
                <!--end::Data-->
              </div>
              <!--end::Info-->
              <div class="btn btn-sm btn-gold" @click="selectedProductId = product.id">{{ $t('btn.write_off') }}</div>
            </div>
            <!--end::Section-->
          </div>
        </div>
      </div>
      <div v-else>
        <div class="lead text-center my-5">
          {{ $t('description.no_data') }}
        </div>
      </div>
    </div>

    <ConfirmationModal @actionConfirmed="writeOffPoints"/>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import ConfirmationModal from '@/components/elements/modals/ConfirmationModal';

export default {
  components: {
    ConfirmationModal
  },
  data: () => ({
    selectedProductId: null
  }),
  async beforeMount() {
    await this.$store.dispatch('loyaltyStore/GET_PRODUCTS');
  },
  computed: {
    ...mapGetters({
      products: 'loyaltyStore/PRODUCTS'
    })
  },
  watch: {
    selectedProductId(id) {
      if (id) this.$bvModal.show('confirmation-modal');
    }
  },
  methods: {
    writeOffPoints() {
      this.$store.dispatch('loyaltyStore/WRITE_OFF_POINTS', this.selectedProductId);
    }
  }
}
</script>

<style lang="scss" scoped>
.product-item::v-deep {
  &.disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}

.product-icon {
  font-size: 24px;
  font-weight: 500;
}
</style>