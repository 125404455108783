<template>
  <div>
    <wallet-info with-additional-data/>
    <div class="card card-custom mt-5">
      <div class="card-body">
        <div class="d-flex align-items-center justify-content-between">
          <div class="">
            <a class="btn btn-sm btn-text-primary font-weight-bold"
               :class="mode === 'recommendations' ? 'btn-light-primary' : 'btn-hover-light-primary'"
               @click="mode = 'recommendations'"
            >
              {{ $t('label.recommendations') }}
            </a>
            <span class="mx-1">/</span>
            <a class="btn btn-sm btn-text-primary font-weight-bold"
               :class="mode === 'history' ? 'btn-light-primary' : 'btn-hover-light-primary'"
               @click="mode = 'history'"
            >
              {{ $t('label.history') }}
            </a>
          </div>
          <div class="">
            <button type="button"
                    class="btn btn-gold font-weight-bold w-100"
                    @click="$router.push({name: 'addRecommendation'})"
            >
              {{ $t('btn.add_recommendation') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-6 mt-5">
        <RecommendationsList v-if="mode === 'recommendations'"/>
        <HistoryList v-if="mode === 'history'"/>
      </div>
      <div class="col-md-6 mt-5">
        <ProductsList/>
      </div>
    </div>
  </div>
</template>

<script>
import HistoryList from '@/components/loyalty/HistoryList';
import ProductsList from '@/components/loyalty/ProductsList';
import RecommendationsList from '@/components/loyalty/RecommendationsList';
import WalletInfo from './components/WalletInfo';

export default {
  components: {
    HistoryList,
    ProductsList,
    RecommendationsList,
    WalletInfo
  },
  data: () => ({
    mode: 'recommendations'
  })
}
</script>