<template>
  <b-modal centered hide-header hide-footer id="confirmation-modal" ref="confirmation-modal">
    <div class="text-center font-weight-bolder mb-5">{{ $t('modal.are_you_sure') }}?</div>

    <div class="d-flex justify-content-center">
      <button type="button"
         class="btn btn-hover-bg-danger btn-text-danger btn-hover-text-white border-0 font-weight-bold mr-2"
         @click="hideModal"
      >
        {{ $t('btn.cancel') }}
      </button>
      <button type="button"
              class="btn btn-hover-bg-success btn-text-success btn-hover-text-white border-0 font-weight-bold mr-2"
              @click="submitModal"
      >
        {{ $t('btn.confirm') }}
      </button>
    </div>
  </b-modal>
</template>

<script>
export default {
  methods: {
    submitModal() {
      this.hideModal();
      this.$emit('actionConfirmed');
    },
    hideModal() {
      this.$refs['confirmation-modal'].hide()
    },
  }
}
</script>