<template>
  <div>
    <div class="card card-custom bgi-no-repeat card-stretch gutter-b">
    <CardLoader v-if="isLoading" :with-text="false"/>
    <div class="card-body">
      <div class="row">
        <div :class="withAdditionalData ? 'col-md-4' : 'col-md-6'">
          <img src="media/custom/loyalty-card.png" class="w-100" alt="">
        </div>
        <div class="d-flex flex-column justify-content-center" :class="withAdditionalData ? 'col-md-4' : 'col-md-6'">
          <div class="d-flex flex-column align-items-center loyalty-balance">
            <div class="font-size-h6 mb-2">{{ $t('label.you_have') }}</div>
            <div class="h1 text-gold font-weight-boldest mb-1">
             {{ `${computedBalance} ${$t('label.points')}` }}
            </div>
            <a target="_blank"
               href="http://orbi.ge/bonus-pdf/bonus-ru.pdf"
               class="font-size-sm text-dark-50 text-hover-primary mt-1"
            >
              {{ $t('help.how_to_get_points') }}?
            </a>
          </div>
        </div>
        <div v-if="withAdditionalData" class="col-md-4 d-flex flex-column align-items-center justify-content-center">
          <div>
            <label class="font-size-h4 text-muted">{{ $t('label.spent_points') }}:</label>
            <span class="font-size-h4 ml-3">{{ computedSpentPoints }}</span>
          </div>
          <div>
            <label class="font-size-h4 text-muted">{{ $t('label.confirmed_points') }}:</label>
            <span class="font-size-h4 ml-3">{{ computedConfirmedPoints }}</span>
          </div>
        </div>
      </div>
      <div v-if="withAddRecommendationBtn" class="d-flex mt-3">
        <button type="button"
                class="btn btn-gold font-weight-bold d-block m-auto"
                @click="$router.push({name: 'addRecommendation'})"
        >
          {{ $t('btn.add_recommendation') }}
        </button>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import CardLoader from '@/components/elements/loaders/CardLoader';

export default {
  components: {
    CardLoader
  },
  props: {
    withAdditionalData: {
      type: Boolean,
      default: false
    },
    withAddRecommendationBtn: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    isLoading: true
  }),
  beforeMount() {
    this.$store.dispatch('loyaltyStore/GET_WALLET');
  },
  computed: {
    ...mapGetters({
      wallet: 'loyaltyStore/WALLET'
    }),
    computedBalance() {
      return !isNaN(this.wallet.points_balance) ? this.wallet.points_balance : '...';
    },
    computedSpentPoints() {
      return !isNaN(this.wallet.spent_points) ? this.wallet.spent_points : '...';
    },
    computedConfirmedPoints() {
      return !isNaN(this.wallet.confirmed_points) ? this.wallet.confirmed_points : '...';
    }
  },
  watch: {
    wallet: {
      immediate: true,
      handler(data) {
        this.isLoading = !Object.keys(data).length;
      }
    }
  }
}
</script>