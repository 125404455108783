<template>
  <div class="card card-custom card-stretch">
    <div class="card-header">
      <div class="card-title">{{ $t('label.history') }}</div>
    </div>
    <div class="card-body">
      <div v-if="history">
        <div v-for="(item, idx) in history"
             :key="idx"
             class="d-flex align-items-center bg-light rounded p-5"
             :class="idx > 0 ? 'mt-3' : ''"
        >
          <!--begin::Icon-->
          <div class="symbol symbol-60 symbol-circle mr-3">
                <span class="d-flex flex-column symbol-label bg-white">
                  <span class="font-size-h4">{{ item.price }}</span>
                  <span class="font-size-xs text-lowercase">{{ $t('label.point_s') }}</span>
                </span>
          </div>
          <!--end::Icon-->
          <!--begin::Title-->
          <div class="d-flex justify-content-between align-items-center w-100">
            <div class="d-flex flex-column flex-grow-1">
              <div class="font-weight-bold text-dark-75 font-size-lg mb-1">
                <div class="d-flex align-items-center font-weight-bold font-size-h4 text-wrap">
                  <span>{{ item.product.name }}</span>
                </div>
              </div>
              <span class="font-size-sm font-weight-bold">
                {{ item.product.description }}
              </span>
            </div>
            <div class="d-flex flex-column">
              <span class="label label-inline mb-2"
                    :class="`label-${getStatusColor(item.status.name)}`"
              >{{ item.status.name }}</span>
              <span class="font-size-md text-nowrap">{{ item.created_at }}</span>
            </div>
          </div>
          <!--end::Title-->
        </div>
      </div>
      <div v-else>
        <div class="lead text-center my-5">
          {{ $t('description.no_data') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  beforeMount() {
    this.$store.dispatch('loyaltyStore/GET_HISTORY');
  },
  computed: {
    ...mapGetters({
      history: 'loyaltyStore/HISTORY'
    })
  },
  methods: {
    getStatusColor(status) {
      let className;
      switch (status) {
        case 'confirmed':
          className = 'success';
          break;
        case 'new':
          className = 'warning';
          break;
        default:
          className = 'dark';
          break;
      }

      return className;
    },
  }
}
</script>