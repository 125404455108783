<template>
  <div class="card card-custom card-stretch">
    <CardLoader v-if="isLoading" :with-text="false"/>
    <div class="card-header">
      <div class="card-title">{{ $t('label.recommendations') }}</div>
    </div>
    <div class="card-body">
      <div v-if="recommendations.length" class="d-flex flex-column justify-content-between h-100">
        <div>
          <div v-for="(recommendation, idx) in recommendations"
               :key="idx"
               class="d-flex align-items-center bg-light rounded p-5"
               :class="idx > 0 ? 'mt-3' : ''"
          >
            <!--begin::Title-->
            <div class="d-flex justify-content-between align-items-center w-100">
              <div class="d-flex flex-column w-100">
                <div class="font-weight-bold text-dark-75 font-size-lg mb-1">
                  <span>{{ recommendation.client }}</span>
                </div>
              </div>
              <div class="d-flex flex-column">
                <span class="font-size-md text-nowrap">{{ recommendation.created_at }}</span>
              </div>
            </div>
            <!--end::Title-->
          </div>
        </div>
      </div>
      <div v-else>
        <div class="lead text-center my-5">
          {{ $t('description.no_data') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import CardLoader from '@/components/elements/loaders/CardLoader';

export default {
  components: {
    CardLoader
  },
  props: {
    recommendationsStatus: {
      default: 'all'
    }
  },
  data: () => ({
    isLoading: true
  }),
  beforeMount() {
    this.getData();
  },
  computed: {
    ...mapGetters({
      recommendations: 'loyaltyStore/RECOMMENDATIONS'
    })
  },
  watch: {
    recommendationsStatus() {
      this.getData();
    },
    recommendations(data) {
      if (data) {
        this.isLoading = false;
      }
    }
  },
  methods: {
    getData() {
      this.isLoading = true;
      this.$store.dispatch('loyaltyStore/GET_RECOMMENDATIONS', {
        status: this.recommendationsStatus
      });
    }
  }
}
</script>